import {
  GlobalPageBackgroundColor,
  LandingPageBaseProps,
  PageFeaturesProps,
  PageSingleFeatureProps,
} from '@mono/brand';
import { UserType } from '@mono/data-codegen/client/graphql-gateway/graphql';
import { UserClickData } from '@mono/tracking';
import { scrollToTarget } from '@mono/util-dom';

import { trackUserClick } from '~/libs/tracking';

import { triggerSheerIdModal } from './sheerId';
import {
  Audience,
  FeaturesItem,
  LandingPageHeroSection,
  LandingPageSection,
  LandingPageSectionWithCTA,
  LandingPageSingleFeatureSection,
} from './types';

export const mapBackgroundColor: {
  [label: string]: GlobalPageBackgroundColor;
} = {
  Beige: 'beige',
  'Pale Blue': 'paleBlue',
  'Pale Green': 'paleGreen',
  'Pale Pink': 'palePink',
  'Pale Yellow': 'paleYellow',
  White: 'white',
};

export const mapEyebrowProps = (
  section: LandingPageHeroSection | LandingPageSingleFeatureSection
): PageSingleFeatureProps['eyebrow'] => {
  if (!section.eyebrow) return undefined;

  if (section.type === 'PageHero') {
    return {
      text: section.eyebrow,
      accent: !section.sectionBackgroundColor,
    };
  }

  return {
    text: section.eyebrow,
  };
};

export const mapFeatureProps = (
  item: FeaturesItem
): PageFeaturesProps['features'][number] => {
  const media = {
    title: item.title ?? undefined,
    desc: item.description ?? undefined,
  };

  switch (item.type) {
    case 'FeaturedImage':
    case 'FeaturedIcon':
      return {
        ...media,
        imgSrc: item.image.url,
        imgAlt: item.image.description ?? '',
      };

    case 'FeaturedStatistic':
      return {
        ...media,
        statText: item.statistic,
      };

    default:
      return media;
  }
};

export const mapFeaturesMediaType: {
  [type: string]: PageFeaturesProps['featuresMedia'];
} = {
  FeaturedImage: 'image',
  FeaturedIcon: 'icon',
  FeaturedStatistic: 'stat',
};

export const mapMediaProps = (
  media:
    | LandingPageHeroSection['media']
    | LandingPageSingleFeatureSection['media']
): PageSingleFeatureProps['media'] => {
  switch (media.type) {
    case 'Image':
      return {
        type: 'image',
        src: media.file.url,
        alt: media.alt,
        linkUrl: media?.linkUrl ?? undefined,
      };

    case 'Video':
      return {
        type: 'video',
        videoUrl: media.url,
        videoTitle: media.title,
        placeholderImage: media.placeholderImage?.url ?? undefined,
      };

    default:
      return undefined;
  }
};

type CTAButtonType = NonNullable<LandingPageBaseProps['cta']>['buttonType'];

const mapCTAButtonType: {
  [type: string]: CTAButtonType;
} = {
  'CTA Button': 'cta',
  'Fill Button': 'fill',
};

type LandingPageBasePropsWithCTA = LandingPageBaseProps['cta'];

export const extractCTA = (
  cta?: LandingPageSectionWithCTA['cta'],
  trackingMeta?: Partial<UserClickData>
): LandingPageBasePropsWithCTA => {
  if (!cta) {
    return undefined;
  }

  return {
    text: cta.text,
    href: cta.href,
    buttonType: cta.buttonType ? mapCTAButtonType[cta.buttonType] : undefined,
    onClick: async (event) => {
      const scrollTargetId = cta.href.startsWith('#') ? cta.href.slice(1) : '';
      const scrollTarget = document.getElementById(scrollTargetId);
      if (scrollTarget && !cta.sheerIdProgramId) {
        // prevent default link behavior since we have an href (the scroll target id)
        event.preventDefault();
        scrollToTarget(scrollTarget);
      }

      if (cta.sheerIdProgramId) {
        event.preventDefault();
        await triggerSheerIdModal(cta.sheerIdProgramId);
      }

      trackUserClick({
        target: 'cta_button',
        href: cta.href,
        ...trackingMeta,
      });
    },
  };
};

export const extractCTAsFromCollection = (
  ctasCollection?: LandingPageSectionWithCTA['additionalCtasCollection'],
  trackingMeta?: Partial<UserClickData>
): LandingPageBasePropsWithCTA[] => {
  if (!ctasCollection?.items) {
    return [];
  }

  return ctasCollection.items.map((cta: LandingPageSectionWithCTA['cta']) => {
    if (!cta) {
      return undefined;
    }
    return extractCTA(cta, trackingMeta);
  });
};

const proUserGroups = [
  UserType.Pro,
  UserType.ProOther,
  UserType.ProStudent,
  UserType.ProStudentTrial,
  UserType.Trial,
];

const isProUser = (userType: UserType) => proUserGroups.includes(userType);

export const isLandingPageSectionForUser = (
  section: LandingPageSection,
  userType: UserType
) => {
  const userTypeFilter = isProUser(userType)
    ? Audience.ProOnly
    : Audience.NonPro;

  return !section.audience || section.audience?.includes(userTypeFilter);
};
