import {
  PageFeatures,
  PageHero,
  PagePrefooter,
  PageSingleFeature,
  PageVideoGallery,
  Testimonial,
} from '@mono/brand';
import { ReactFCWithChildren } from '@mono/util-typings';
import React from 'react';

import { trackUserClick } from '~/libs/tracking';

import { BusinessPageHero } from './Exceptions/BusinessPageHero';
import { PricingTable } from './Exceptions/PricingTable/PricingTable';
import {
  extractCTA,
  extractCTAsFromCollection,
  mapEyebrowProps,
  mapFeatureProps,
  mapFeaturesMediaType,
  mapMediaProps,
} from './helpers';
import { LandingPageSectionWrapper } from './LandingPageSectionWrapper';
import {
  isBusinessHeroSection,
  isBusinessPlansSection,
  isFeaturesSection,
  isHeroSection,
  isPrefooterSection,
  isSingleFeatureSection,
  isTestimonialSection,
  isVideoGallerySection,
  LandingPageProps,
} from './types';

export const LandingPage: ReactFCWithChildren<LandingPageProps> = ({
  items,
  slug,
  children,
}) => {
  const trackingMeta = { page_name: slug };

  const onMarkdownLinkClick =
    (context: string) => (event: React.MouseEvent<HTMLAnchorElement>) => {
      trackUserClick({
        context,
        target: 'markdown_link',
        href: event?.currentTarget?.href,
        ...trackingMeta,
      });
    };

  return (
    <main>
      {items.map((section) => {
        const Section = (sectionProps: React.PropsWithChildren) => (
          <LandingPageSectionWrapper
            sectionBackgroundColor={section.sectionBackgroundColor}
            componentName={section.componentName}
          >
            {sectionProps.children}
          </LandingPageSectionWrapper>
        );

        return (
          <React.Fragment key={section.sys.id}>
            {isHeroSection(section) && (
              <Section>
                <PageHero
                  eyebrow={mapEyebrowProps(section)}
                  title={section.title}
                  desc={section.description ?? undefined}
                  textLength={section.textLength}
                  cta={extractCTA(section.cta, trackingMeta)}
                  testId={section.componentName}
                  onAnchorClick={onMarkdownLinkClick(section.componentName)}
                  media={mapMediaProps(section.media)}
                  showImageOnMobile={section.showImageOnMobile ?? undefined}
                  additionalCtas={extractCTAsFromCollection(
                    section.additionalCtasCollection,
                    trackingMeta
                  )}
                />
              </Section>
            )}
            {isBusinessHeroSection(section) && <BusinessPageHero />}
            {isSingleFeatureSection(section) && (
              <Section>
                <PageSingleFeature
                  eyebrow={mapEyebrowProps(section)}
                  title={section.title}
                  desc={section.description ?? undefined}
                  cta={extractCTA(section.cta, trackingMeta)}
                  testId={section.componentName}
                  onAnchorClick={onMarkdownLinkClick(section.componentName)}
                  media={mapMediaProps(section.media)}
                  mediaWidth={section.mediaWidth}
                  hideImageOnMobile={section.hideImageOnMobile ?? undefined}
                  additionalCtas={extractCTAsFromCollection(
                    section.additionalCtasCollection,
                    trackingMeta
                  )}
                />
              </Section>
            )}
            {isFeaturesSection(section) && (
              <Section>
                <PageFeatures
                  title={section.title ?? undefined}
                  desc={section.description ?? undefined}
                  cta={extractCTA(section.cta, trackingMeta)}
                  maxCols={section.maxCols ?? undefined}
                  featuresMedia={
                    mapFeaturesMediaType[section.itemsCollection.items[0].type]
                  }
                  features={section.itemsCollection.items.map((item) =>
                    mapFeatureProps(item)
                  )}
                  testId={section.componentName}
                  onAnchorClick={onMarkdownLinkClick(section.componentName)}
                />
              </Section>
            )}
            {isVideoGallerySection(section) && (
              <Section>
                <PageVideoGallery
                  title={section.title ?? undefined}
                  desc={section.description ?? undefined}
                  cta={extractCTA(section.cta, trackingMeta)}
                  testId={section.componentName}
                  onAnchorClick={onMarkdownLinkClick(section.componentName)}
                  videos={section.videosCollection.videos.map((video) => ({
                    title: video.title,
                    url: video.url,
                    placeholderImage: video.placeholderImage?.url ?? undefined,
                  }))}
                />
              </Section>
            )}
            {isPrefooterSection(section) && (
              <Section>
                <PagePrefooter
                  title={section.title}
                  desc={section.description ?? undefined}
                  cta={extractCTA(section.cta, trackingMeta)}
                  testId={section.componentName}
                  onAnchorClick={onMarkdownLinkClick(section.componentName)}
                  additionalCtas={extractCTAsFromCollection(
                    section.additionalCtasCollection,
                    trackingMeta
                  )}
                />
              </Section>
            )}
            {isTestimonialSection(section) && (
              <Section>
                <div data-testid={section.componentName}>
                  <Testimonial
                    mode="dark"
                    variant="navy"
                    firstName={section.firstName}
                    lastName={section.lastName}
                    occupation={section.jobTitle}
                    company={section.company}
                    quote={section.longQuote}
                    imageUrl={section.image.url}
                    href={section.href}
                    location=""
                    onClick={() =>
                      trackUserClick({
                        context: section.componentName,
                        target: 'learner_testimonial',
                        href: section.href ?? undefined,
                        ...trackingMeta,
                      })
                    }
                  />
                </div>
              </Section>
            )}
            {isBusinessPlansSection(section) && (
              <Section>
                <PricingTable />
              </Section>
            )}
          </React.Fragment>
        );
      })}
      {children}
    </main>
  );
};
