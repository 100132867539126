import {
  LandingPageQuery,
  Maybe,
} from '@mono/data-codegen/contentful-landing-page';

import { DistributiveOmit, RequireSome } from '~/typings/utility';

export type LandingPageParams = {
  slug: string;
};

/**
 * These enum values are exceptions to the automatic `/pages/<slug>` landing page routing,
 * and they must match the slug provided in the Contentful landing page builder.
 * There needs to be a route set up to serve these slugs, but that route does not need to match the slug.
 * Ex: the careers page route is `/about/careers`.
 * See `src/pages/about/careers.tsx` and `src/components/LandingPage/Exceptions/getCareersStaticProps.ts` for how to set this up.
 */
export enum ExceptionSlug {
  Careers = 'careers',
  CareerCenter = 'career-center',
  StudentCenter = 'student-center',
  Business = 'business',
}

export type LandingPageException = {
  exceptionSlug?: ExceptionSlug;
};

type NullableLandingPage = NonNullable<
  LandingPageQuery['pageCollection']
>['items'][number];

export type RawLandingPageRoute = NonNullable<NullableLandingPage>;

export const isLandingPageRouteProps = (
  landingPage?: NullableLandingPage
): landingPage is LandingPageRouteProps =>
  !!(
    landingPage?.slug &&
    landingPage?.backgroundColor &&
    landingPage?.seo?.title &&
    landingPage?.seo?.description &&
    landingPage?.seo?.image?.url &&
    landingPage?.contentCollection?.items.length
  );

type SEOWithNullableImage = RequireSome<
  NonNullable<RawLandingPageRoute['seo']>,
  'title' | 'description' | 'image' | 'isIndexed'
>;

type SEOImage = RequireSome<SEOWithNullableImage['image'], 'url'>;

type SEO = SEOWithNullableImage & {
  image: SEOImage;
};

type RequiredLandingPageRouteProps = RequireSome<
  RawLandingPageRoute,
  'backgroundColor' | 'slug' | 'seo'
>;

// don't need contentCollection because we provide a custom finalizedSectionsList
type LandingPageRoutePropsBase = Omit<
  RequiredLandingPageRouteProps,
  'contentCollection'
>;

export type LandingPageRouteProps = LandingPageRoutePropsBase &
  React.PropsWithChildren & {
    seo: SEO;
    finalizedSectionsList: LandingPageSection[];
  };

type RawVariationContainer = Extract<
  NonNullableLandingPageSection,
  { type: 'VariationContainer' }
>;

export type LandingPageVariationContainer = Omit<
  RawVariationContainer,
  'meta' | 'variationsCollection'
> & {
  meta: Record<string, string>;
  variationsCollection: {
    items: LandingPageSection[];
  };
};

// This comes from the raw Contentful response
export type RawLandingPageSection =
  | LandingPageSection
  | LandingPageVariationContainer
  | null;

type NonNullableLandingPageSection = NonNullable<
  RawLandingPageRoute['contentCollection']
>['items'][number];

type LandingPageSectionWithoutVariation = Exclude<
  NonNullableLandingPageSection,
  { type: 'VariationContainer' }
>;

export enum Audience {
  NonPro = 'Non-pro',
  ProOnly = 'Pro only',
}

export type LandingPageSection =
  NonNullable<LandingPageSectionWithoutVariation> & {
    sectionBackgroundColor?: Maybe<SectionBackgroundColor>;
    componentName?: string;
    audience?: Audience[] | null;
  };

export type SectionBackgroundColor = 'navy' | 'hyper' | 'yellow';

export type LandingPageProps = {
  items: LandingPageSection[];
  slug: string;
};

type RawLandingPageSectionWithCTA = Extract<
  LandingPageSection,
  | { type: 'PageHero' }
  | { type: 'PageSingleFeature' }
  | { type: 'PageFeatures' }
  | { type: 'PageVideoGallery' }
  | { type: 'PagePreFooter' }
>;

export type SectionCTA = RequireSome<
  NonNullable<RawLandingPageSectionWithCTA['cta']>,
  'text' | 'href'
>;

export type LandingPageSectionWithCTA = DistributiveOmit<
  RawLandingPageSectionWithCTA,
  'cta' | 'additionalCtasCollection'
> & {
  cta?: Maybe<SectionCTA>;
  additionalCtasCollection?: {
    items: Maybe<SectionCTA>[];
  };
};

type RawLandingPageHeroSection = Extract<
  LandingPageSectionWithCTA,
  { type: 'PageHero' }
>;

type LandingPageHeroSectionWithNullableMedia = RequireSome<
  RawLandingPageHeroSection,
  'componentName' | 'textLength' | 'title' | 'media'
>;

type RawHeroImageMedia = Extract<
  LandingPageHeroSectionWithNullableMedia['media'],
  {
    type: 'Image';
  }
>;

type HeroImageMediaWithNullableFile = RequireSome<
  RawHeroImageMedia,
  'alt' | 'file'
>;

type HeroImageMediaFile = RequireSome<
  HeroImageMediaWithNullableFile['file'],
  'url'
>;

export type HeroImageMedia = HeroImageMediaWithNullableFile & {
  file: HeroImageMediaFile;
};

type RawHeroVideoMedia = Extract<
  LandingPageHeroSectionWithNullableMedia['media'],
  {
    type: 'Video';
  }
>;

type HeroVideoMedia = RequireSome<RawHeroVideoMedia, 'title' | 'url'>;

export type LandingPageHeroSection = LandingPageHeroSectionWithNullableMedia & {
  media: HeroImageMedia | HeroVideoMedia;
  textLength: 'long' | 'short';
};

export const isHeroSection = (
  section: LandingPageSection
): section is LandingPageHeroSection => section.type === 'PageHero';

export const isBusinessHeroSection = (
  section: LandingPageSection
): section is LandingPageHeroSection => section.type === 'BusinessPageHero';

type RawLandingPageSingleFeatureSection = Extract<
  LandingPageSectionWithCTA,
  { type: 'PageSingleFeature' }
>;

type LandingPageSingleFeatureSectionWithNullableMedia = RequireSome<
  RawLandingPageSingleFeatureSection,
  'componentName' | 'title' | 'media' | 'mediaWidth'
>;

type RawSingleFeatureImageMedia = Extract<
  LandingPageSingleFeatureSectionWithNullableMedia['media'],
  {
    type: 'Image';
  }
>;

type SingleFeatureImageMediaWithNullableFile = RequireSome<
  RawSingleFeatureImageMedia,
  'alt' | 'file'
>;

type SingleFeatureImageMediaFile = RequireSome<
  SingleFeatureImageMediaWithNullableFile['file'],
  'url'
>;

type SingleFeatureImageMedia = SingleFeatureImageMediaWithNullableFile & {
  file: SingleFeatureImageMediaFile;
};

type RawSingleFeatureVideoMedia = Extract<
  LandingPageSingleFeatureSectionWithNullableMedia['media'],
  {
    type: 'Video';
  }
>;

type SingleFeatureVideoMedia = RequireSome<
  RawSingleFeatureVideoMedia,
  'title' | 'url'
>;

export type LandingPageSingleFeatureSection =
  LandingPageSingleFeatureSectionWithNullableMedia & {
    media: SingleFeatureImageMedia | SingleFeatureVideoMedia;
    mediaWidth: 3 | 4 | 5 | 6;
  };

export const isSingleFeatureSection = (
  section: LandingPageSection
): section is LandingPageSingleFeatureSection =>
  section.type === 'PageSingleFeature';

type RawLandingPageFeaturesSection = Extract<
  LandingPageSectionWithCTA,
  { type: 'PageFeatures' }
>;

type LandingPageFeaturesSectionWithNullableItems = RequireSome<
  RawLandingPageFeaturesSection,
  'componentName' | 'itemsCollection'
>;

type RawFeature = NonNullable<
  LandingPageFeaturesSectionWithNullableItems['itemsCollection']['items'][number]
>;

type RawFeaturedIcon = Extract<
  RawFeature,
  {
    type: 'FeaturedIcon';
  }
>;

type FeaturedIconWithNullableImage = RequireSome<RawFeaturedIcon, 'image'>;

type FeaturedIconImage = RequireSome<
  FeaturedIconWithNullableImage['image'],
  'url'
>;

type FeaturedIcon = FeaturedIconWithNullableImage & {
  image: FeaturedIconImage;
};

type RawFeaturedImage = Extract<
  RawFeature,
  {
    type: 'FeaturedImage';
  }
>;

type FeaturedImageWithNullableImage = RequireSome<RawFeaturedImage, 'image'>;

type FeaturedImageImage = RequireSome<
  FeaturedImageWithNullableImage['image'],
  'url'
>;

type FeaturedImage = FeaturedImageWithNullableImage & {
  image: FeaturedImageImage;
};

type RawFeaturedInformation = Extract<
  RawFeature,
  {
    type: 'FeaturedInformation';
  }
>;

type FeaturedInformation = RequireSome<RawFeaturedInformation, 'title'>;

type RawFeaturedStatistic = Extract<
  RawFeature,
  {
    type: 'FeaturedStatistic';
  }
>;

type FeaturedStatistic = RequireSome<RawFeaturedStatistic, 'statistic'>;

export type FeaturesItem =
  | FeaturedIcon
  | FeaturedImage
  | FeaturedInformation
  | FeaturedStatistic;

export type LandingPageFeaturesSection = Omit<
  LandingPageFeaturesSectionWithNullableItems,
  'itemsCollection' | 'maxCols'
> & {
  itemsCollection: {
    items: FeaturesItem[];
  };
  maxCols?: Maybe<1 | 2 | 3 | 4>;
};

export const isFeaturesSection = (
  section: LandingPageSection
): section is LandingPageFeaturesSection => section.type === 'PageFeatures';

type RawLandingPageVideoGallerySection = Extract<
  LandingPageSectionWithCTA,
  { type: 'PageVideoGallery' }
>;

type LandingPageVideoGallerySectionWithNullableVideos = RequireSome<
  RawLandingPageVideoGallerySection,
  'componentName' | 'videosCollection'
>;

type RawVideoGalleryVideo = NonNullable<
  LandingPageVideoGallerySectionWithNullableVideos['videosCollection']['videos'][number]
>;

export type VideoGalleryVideo = RequireSome<
  RawVideoGalleryVideo,
  'url' | 'title'
>;

export type LandingPageVideoGallerySection = Omit<
  LandingPageVideoGallerySectionWithNullableVideos,
  'videosCollection'
> & {
  videosCollection: {
    videos: VideoGalleryVideo[];
  };
};

export const isVideoGallerySection = (
  section: LandingPageSection
): section is LandingPageVideoGallerySection =>
  section.type === 'PageVideoGallery';

type RawLandingPagePrefooterSection = Extract<
  LandingPageSectionWithCTA,
  { type: 'PagePreFooter' }
>;

export type LandingPagePrefooterSection = RequireSome<
  RawLandingPagePrefooterSection,
  'componentName' | 'title'
>;

export const isPrefooterSection = (
  section: LandingPageSection
): section is LandingPagePrefooterSection => section.type === 'PagePreFooter';

type RawLandingPageTestimonialSection = Extract<
  LandingPageSection,
  { type: 'PageTestimonial' }
>;

type LandingPageTestimonialSectionWithNullableImage = RequireSome<
  RawLandingPageTestimonialSection,
  | 'componentName'
  | 'image'
  | 'firstName'
  | 'lastName'
  | 'jobTitle'
  | 'shortQuote'
  | 'longQuote'
>;

type TestimonialImage = RequireSome<
  LandingPageTestimonialSectionWithNullableImage['image'],
  'url'
>;

export type LandingPageTestimonialSection =
  LandingPageTestimonialSectionWithNullableImage & {
    image: TestimonialImage;
  };

export const isTestimonialSection = (
  section: LandingPageSection
): section is LandingPageTestimonialSection =>
  section.type === 'PageTestimonial';

type RawLandingPageBusinessPlansSection = Extract<
  LandingPageSection,
  { type: 'PageBusinessPlans' }
>;

export type LandingPageBusinessPlansSection = RequireSome<
  RawLandingPageBusinessPlansSection,
  'componentName' | 'plans' | 'features'
>;

export const isBusinessPlansSection = (
  section: LandingPageSection
): section is LandingPageBusinessPlansSection =>
  section.type === 'PageBusinessPlans';
