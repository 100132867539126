/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import { Currency } from '@mono/data-codegen/monolith';

export enum CFBCurrency {
  Dkk = 'DKK',
  Brl = 'BRL',
  Bgn = 'BGN',
  Chf = 'CHF',
  Czk = 'CZK',
  Hrk = 'HRK',
  Huf = 'HUF',
  Mxn = 'MXN',
  Ron = 'RON',
}

export enum CurrencySymbol {
  Dollar = '$',
  Pound = '£',
  Euro = '€',
  Rupee = '₹',
  Krona = 'kr',
  Krone = 'kr',
  ZLOTY = 'zł',
  Lev = 'Лв.',
  Real = 'R$',
  Franc = 'Fr.',
  Koruna = 'Kč',
  Kuna = 'kn',
  Forint = 'Ft',
  Leu = 'lei',
}

export type AllCurrency = Currency | CFBCurrency;

const currencySymbols: Record<AllCurrency, CurrencySymbol> = {
  [Currency.Usd]: CurrencySymbol.Dollar,
  [Currency.Cad]: CurrencySymbol.Dollar,
  [Currency.Aud]: CurrencySymbol.Dollar,
  [Currency.Eur]: CurrencySymbol.Euro,
  [Currency.Gbp]: CurrencySymbol.Pound,
  [Currency.Inr]: CurrencySymbol.Rupee,
  [Currency.Sek]: CurrencySymbol.Krona,
  [Currency.Nok]: CurrencySymbol.Krone,
  [Currency.Pln]: CurrencySymbol.ZLOTY,
  [CFBCurrency.Bgn]: CurrencySymbol.Lev,
  [CFBCurrency.Brl]: CurrencySymbol.Real,
  [CFBCurrency.Chf]: CurrencySymbol.Franc,
  [CFBCurrency.Czk]: CurrencySymbol.Koruna,
  [CFBCurrency.Dkk]: CurrencySymbol.Krone,
  [CFBCurrency.Hrk]: CurrencySymbol.Kuna,
  [CFBCurrency.Huf]: CurrencySymbol.Forint,
  [CFBCurrency.Mxn]: CurrencySymbol.Dollar,
  [CFBCurrency.Ron]: CurrencySymbol.Leu,
};

export const getCurrencySymbol = (currency: AllCurrency) =>
  currencySymbols[currency];
